export function dateNow(datetime) {
  const date_now =
    datetime.getFullYear() + "-" +
    ("00" + (datetime.getMonth() + 1)).slice(-2) + "-" +
    ("00" + datetime.getDate()).slice(-2) + " " +
    ("00" + datetime.getHours()).slice(-2) + ":" +
    ("00" + datetime.getMinutes()).slice(-2) + ":" +
    ("00" + datetime.getSeconds()).slice(-2);

  return date_now;
}