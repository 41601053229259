import React from "react";

import { Switch, Redirect, Route, RouteComponentProps } from "react-router-dom";

import { useAuth } from "../hooks/auth";

import {
  Home as HomeAssistant,
  Solicitation as SolicitationAssistant,
} from "../pages/Assistant";
import {
  Home as HomeDirection,
  Solicitation as SolicitationDirection,
} from "../pages/Direction";
import Login from "../pages/Login";

interface LocationProps extends RouteComponentProps {
  location: RouteLocation;
}

interface RouteLocation extends Location {
  state: {
    userType: string;
    id: string;
  };
}

const Routes: React.FC = () => {
  const { user = true } = useAuth();

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route
        path="/home"
        render={({ location }: LocationProps) => {
          return location.state?.userType === undefined
            ? (<Login />)
            : !!user
              ? (
                location.state?.userType === "social"
                  ? (<HomeAssistant />)
                  : (<HomeDirection />)
              )
              : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: location },
                  }}
                />
              );
        }}
      />
      <Route
        path="/solicitation"
        isPrivate
        render={({ location }: LocationProps) => {
          return location.state?.userType === undefined
            ? (<Login />)
            : !!user
              ? (
                location.state.userType === "social"
                  ? (
                    <SolicitationAssistant
                      id={location.state.id}
                    />
                  )
                  : (
                    <SolicitationDirection
                      id={location.state.id}
                    />
                  )
              )
              : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: location },
                  }}
                />
              );
        }}
      />
    </Switch>
  );
};

export default Routes;
