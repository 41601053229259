import styled from "styled-components";
import { colors } from "../../../assets/colors";

export const Container = styled.div`
  justify-content: space-evenly;
  padding: 20px 30px;
`;

export const Loader = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderLinkGenerator = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 5px;
  height: 5px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Logo = styled.img`
  display: flex;
  align-self: center;
`;

export const SearchContainer = styled.form`
  display: flex;
  align-self: center;
  width: 100%;
  flex: 1;
  max-width: 800px;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
`;

export const WelcomeContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  flex: 1;
  max-width: 800px;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  align-items: baseline;
`;

export const FormGeneratorContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  flex: 1;
  max-width: 800px;
  flex-direction: column;
  margin: 30px;
  //border: 1px solid #000;
  align-items: baseline;
  padding: 5px;
`;

export const BoxFormGeneratorContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  flex: 1;
  flex-direction: column;
  margin: 5px 0px;
  align-items: baseline;
  border: 1px solid #000;
  padding: 10px;
`;

export const InnerFormGeneratorContainer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  flex: 1;
  flex-direction: row;
  margin: 5px 0px;
  align-items: baseline;
`;

export const Search = styled.input`
  width: 33%;
  padding: 5px;
`;

export const TypeSearch = styled.select`
  width: 33%;
  margin: 0 10px 0 10px;
  /* padding: 5px; */
`;

export const SubmitSearch = styled.button`
  width: 20%;
  padding: 5px;
  margin-left: 10px;
`;

export const Welcome = styled.span`
  color: #000;
`;

export const Instructions = styled.span`
  color: #000;
  text-align: justify;
`;

export const ListsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  :first-child {
    margin-right: 10px;
  }
  :nth-child(2) {
    margin-left: 10px;
  }
`;

export const InnerListContainer = styled.div`
  overflow: auto;
  max-height: 400px;
  flex: 1;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

export const ListContainer = styled.div`
  flex: 1;
  align-self: flex-start;
  border: 1px solid #232323;
  flex-direction: column;
  max-width: 400px;
  :first-child {
    margin-right: 10px;
  }
  :nth-child(2) {
    margin-left: 10px;
  }
  @media (max-width: 533px) {
    :first-child {
      margin-right: 0px;
      margin-bottom: 20px;
    }
    :nth-child(2) {
      margin-left: 0px;
    }
  }
`;

export const ListTitleOpen = styled.h3`
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  background-color: ${colors.lightBlue};
`;

export const ListTitleClosed = styled(ListTitleOpen)`
  background-color: ${colors.darkBlue};
`;

export const ListItem = styled.span`
  background-color: ${colors.lighterBlue};
  border: 1px solid #232323;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  padding: 5px 10px;
  &:hover {
    opacity: 0.5;
  }
`;
