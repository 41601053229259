import styled from "styled-components";
import { colors } from "../../assets/colors";

export const Container = styled.div`
  justify-content: flex-start;
  flex-grow: 1;
  display: flex;
  margin: 50px 0;
  flex-direction: column;
`;

export const Title = styled.h1`
  margin-bottom: 10px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 3px;
  background-color: #f2f2f2;
  padding: 20px;
  overflow: hidden;
  max-width: 400px;
  border: 1px solid #c2c2c2;

  @media (max-width: 800px) {
    flex-direction: column;
    max-width: 300px;
    position: relative;
  }
`;

export const Instructions = styled.span`
  display: flex;
  text-align: justify;
  margin-top: 15px;
  color: #000;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img`
  display: flex;
  align-self: center;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
`;

export const Input = styled.input`
  margin-bottom: 15px;
  color: #000;
  border: 1px solid #c2c2c2;
  /* border: none; */
  ::-webkit-input-placeholder {
    color: #000;
  }
  border-radius: 3px;
  padding: 5px;
  background-color: ${colors.lighterBlue};
`;

export const Button = styled.button`
  border-radius: 3px;
  padding: 10px 0;
  border: none;
  color: #fff;
  background-color: ${colors.darkBlue};
`;
