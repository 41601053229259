import React, { useState, SyntheticEvent, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/auth";

import { ReactComponent as Logo } from "../../assets/images/censa_social_logo.svg";

import {
  Container,
  Input,
  Instructions,
  Form,
  Card,
  Button,
  Title,
} from "./styles";


const Login: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function getData() {
      const user = await localStorage.getItem("@CensaAssist:user");
      setLogin(user ? user : "");
    }
    getData();
  }, []);

  async function handleLogin(e: SyntheticEvent) {
    e.preventDefault();
    setErrorMessage("");
    if (login && pass) {
      const { type, error } = await signIn({ login, pass });
      if (error) {
        setErrorMessage(error);
      } else {
        history.push("/home", { userType: type });
      }
    } else {
      setErrorMessage("Preencha todos os campos");
    }
  }

  return (
    <Container>
      <Logo width="300" style={{ alignSelf: "center", marginBottom: 10 }} />
      <Card>
        <Title>Fazer Login</Title>
        <Form onSubmit={handleLogin}>
          <Input
            value={login}
            onChange={(evt) => setLogin(evt.currentTarget.value)}
            placeholder="Usuário"
          />
          <Input
            value={pass}
            type="password"
            onChange={(evt) => setPass(evt.currentTarget.value)}
            placeholder="Senha"
          />
          {errorMessage !== "" &&
            <h4 style={{ color: '#FF0000', paddingBottom: '15px' }} >{errorMessage}</h4>
          }
          <Button type="submit">Entrar</Button>
          <Instructions>
            Se você tem dúvidas sobre como efetuar login ou sobre o
            funcionamento do Censa Assistência, por favor, entre em contato com
            o Censanet pelo ramal 3026 ou com a Tesouraria da escola no ramal
            2902.
          </Instructions>
        </Form>
      </Card>
    </Container>
  );
};

export default Login;
