import styled from "styled-components";

interface StatusProps {
  currentStatus: string;
}
interface ObsProps {
  tem_obs: boolean;
}

export const Form = styled.form`
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-grow: 1;
  border-width: 1;
  border: 1px solid #000;
  margin: 5px 0;
  padding: 5px;
`;

export const BoxContainerObs = styled.div<ObsProps>`
  display: flex;
  flex-grow: 1;
  border-width: 1;
  border: 1px solid #000;
  margin: 5px 0;
  padding: 5px;
  height: ${props => props.tem_obs ? "auto" : "50px"};
`;

export const BoxContainerTesouraria = styled.div<StatusProps>`
  display: flex;
  flex-grow: 1;
  border-width: 1;
  border: ${props => props.currentStatus !== "emavaliacao" ? "1px solid #000" : "1px solid #ff7777"};
  margin: 5px 0;
  padding: 5px;
`;

export const ColumnContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const RowContainerTesouraria = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: baseline;
  padding: 10px;
`;

export const InnerRowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  margin: 2.5px 0;
  /* margin-left: 10px; */
`;

export const InnerColumnContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
`;

export const BoldLabel = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: flex-start;
  margin-left: 5px;
`;

export const Label = styled.span`
  margin: 2.5px 0;
  font-size: 12px;
  align-items: flex-start;
  color: #333;
`;

export const LabelDespesas = styled.span`
  margin: 2.5px 0 0 5px;
  font-size: 12px;
  align-items: flex-start;
  color: #333;
`;

export const DataLabel = styled.span`
  font-size: 16px;
  //text-decoration: underline;
  margin-left: 5px;
  display: flex;
`;

export const CheckBox = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-self: flex-start;
  margin-left: 5px;
  border: 1px solid #000;
  border-radius: 3px;
`;

export const CheckBoxContent = styled.span`
  text-align: center;
  font-size: 12px;
`;

export const Container = styled.div`
  padding: 30px;
  display: flex;
  align-self: center;
  max-width: 1000px;
  height: 100vh;
  flex: 1;
  flex-direction: column;
`;

export const Loader = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const RowContainerHeader = styled.div <StatusProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  border: 1px solid #000;
  background-color: ${props => props.currentStatus !== "emavaliacao" ? "#d8ffc0" : "#ffc7c7"};
  padding: 5px;
`;

export const ButtonSubmitContainer = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  margin-bottom: 50px;
`;

export const ButtonSubmit = styled.button`
  padding: 5px;
  display: inline-block;
  margin-bottom: 50px;
`;

export const Button = styled.button`
  padding: 5px;
`;

export const DataParecerLabel = styled.h2`
  display: flex;
  color: red;
  padding-top: 20px;
  padding-bottom: 50px;
`;
