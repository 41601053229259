import { TextField, IconButton, Tooltip, Zoom, Fade, Modal, Backdrop } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import GetAppIcon from '@material-ui/icons/GetApp';
import { Alert } from "@material-ui/lab";
import React, { useState, SyntheticEvent, useEffect } from "react";
import { useHistory } from "react-router-dom";

import api from "../../../services/api";
import { dateNow } from "../../../Utils/Date"

import {
  Container,
  Button,
  ButtonSubmit,
  ButtonSubmitContainer,
  RowContainerHeader,
  BoxContainer,
  BoxContainerParecer,
  BoxContainerObs,
  Form,
  RowContainer,
  InnerRowContainer,
  Label,
  LabelDespesas,
  DataLabel,
  InnerColumnContainer,
  CheckBoxContent,
  CheckBox,
  ColumnContainer,
  BoldLabel,
  Loader,
  DataParecerLabel
} from "./styles";


interface Props {
  id: string;
}
interface Aluno {
  segmento: number;
  irmaos: string;
  nascimento: string;
  nome: string;
  temirmaos: string;
  ano: string;
  turma: string;
  status: string;
  obs: string;
  parecer: string;
  datahora: string;
  datahora_parecer: string;
  datahora_parecer_dia: string;
  datahora_parecer_horario: string;
  percentual_concedido: string;
  url: string;
  formToken: string;
}
interface Complementar {
  bairro: string;
  celular: string;
  cep: string;
  cidade: string;
  email: string;
  numero: string;
  reside_com: string;
  reside_com_outro: string;
  responsavel_financeiro: string;
  rua: string;
}
interface Trabalho {
  onde_trabalha: string;
  rua: string
  bairro: string;
  numero: string;
  cidade: string;
  ramal: string;
  cargo: string;
  celular: string;
  cep: string;
  email: string;
  fax: string;
  salario_bruto: string;
  telefone: string;
}
interface Responsavel {
  tipo: string;
  atualmente_esta: string;
  ci: string;
  condicao_trabalho: string;
  cpf: string;
  nome: string;
  oriunda_de: string;
  profissao: string;
  renda_complementar: string;
  trabalhos: Trabalho[];
}
interface Carro {
  marca: string;
  modelo: string;
  ano: string;
}
interface Parente {
  idade: string;
  nome: string;
  outras_rendas: string;
  parentesco: string;
  profissao: string;
  salario_bruto: string;
}

interface ComposicaoFamiliar {
  carros: Carro[];
  despesa_familiar: string;
  doencas_familia: string;
  doencas_familia_qual: string;
  doencas_familia_tratamento: string;
  outros_imoveis: string;
  outros_imoveis_quais: string;
  parentes: Parente[];
  possui_carro: string;
  possui_carro_quantos: string;
  renda_familiar: string;
  renda_familiar_dependentes: string;
  renda_per_capta: string;
  residencia_imovel: string;
  residencia_imovel_valor: string;
}
interface Despesas {
  correntes: Despesa[];
  extras: Despesa[];
  eventuais: Despesa;
  outros: Despesa[];
}
interface Despesa {
  nome: string;
  valor: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const Solicitation: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingMessageCopiedLink, setloadingMessageCopiedLink] = useState(false);

  const [base, setBase] = useState<Aluno>({} as Aluno);
  const [complementares, setComplementares] = useState<Complementar>({} as Complementar);
  const [responsaveis, setResponsaveis] = useState<Responsavel[]>({} as Responsavel[]);
  const [composicaoFamiliar, setComposicaoFamiliar] = useState<ComposicaoFamiliar>({} as ComposicaoFamiliar);
  const [despesas, setDespesas] = useState<Despesas>({} as Despesas);
  const [attachedDocs, setAttachedDocs] = useState<[]>({} as []);

  const confirmationMessage = "ATENCÃO!!! Favor confirmar todos os dados antes de prosseguir. Após clicar em OK a solicitação não poderá ser mais aberta para análise.";

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      try {
        const formData = new FormData();
        const token = await localStorage.getItem("@CensaAssist:token");
        let response = await api.post("ws/form/" + String(Number(id)), formData, {
          headers: {
            Authorization: `CensaApp ${token}`,
          },
        });

        let data_base = response.data.return.aluno;
        if (data_base.parecer) {
          const dt = data_base.datahora_parecer.split(" ");
          data_base = {
            ...data_base,
            datahora_parecer_dia: dt[0],
            datahora_parecer_horario: dt[1]
          }
        }

        const dts = data_base.datahora.split(" ");
        data_base = { ...data_base, datahora: dts[0] };

        const tk = data_base.url.split("/");
        data_base = { ...data_base, formToken: tk[2] };

        setBase(data_base);
        setComplementares(response.data.return.complementar);
        setResponsaveis(response.data.return.responsaveis);
        setComposicaoFamiliar(response.data.return.familia);
        setDespesas(response.data.return.despesas);
        setAttachedDocs(response.data.return.docs);

      } catch (err) {
        window.alert("Ocorreu um erro para abrir esta solicitação. Por favor, tente novamente mais tarde.");
        console.log(err);
        history.push("/Home", { userType: "social" });
      }
      setLoading(false);
    }
    getData();
  }, []);

  function renderForm() {
    return (
      <Form>
        {/**Nome e turma */}
        <BoxContainer>
          <RowContainer>
            <InnerRowContainer>
              <Label>Nome:</Label>
              <DataLabel>{base.nome}</DataLabel>
            </InnerRowContainer>
            <InnerRowContainer>
              <Label>Ano:</Label>
              <DataLabel>{base.ano + "°"}</DataLabel>
            </InnerRowContainer>
            <InnerRowContainer>
              <Label>Turma:</Label>
              <DataLabel>{base.turma}</DataLabel>
            </InnerRowContainer>
          </RowContainer>
        </BoxContainer>
        {/**Data de Nascimento e Segmento (Ensino Infantil e etc.) */}
        <RowContainer>
          <BoxContainer style={{ marginRight: 5 }}>
            <InnerRowContainer>
              <Label>Data de Nascimento:</Label>
              <DataLabel>{base.nascimento}</DataLabel>
            </InnerRowContainer>
          </BoxContainer>
          <InnerRowContainer>
            <Label>Educação Infantil</Label>
            <CheckBox>
              <CheckBoxContent>{base.segmento === 1 && "X"}</CheckBoxContent>
            </CheckBox>
          </InnerRowContainer>
          <InnerRowContainer>
            <Label>1° ao 5° ano E.F.</Label>
            <CheckBox>
              <CheckBoxContent>
                {base.segmento === 2 && "X"}
              </CheckBoxContent>
            </CheckBox>
          </InnerRowContainer>
          <InnerRowContainer>
            <Label>6° ao 9° ano E.F.</Label>
            <CheckBox>
              <CheckBoxContent>
                {base.segmento === 3 && "X"}
              </CheckBoxContent>
            </CheckBox>
          </InnerRowContainer>
          <InnerRowContainer>
            <Label>Ensino Médio</Label>
            <CheckBox>
              <CheckBoxContent>{base.segmento === 4 && "X"}</CheckBoxContent>
            </CheckBox>
          </InnerRowContainer>
          <InnerRowContainer>
            <Label>Normal</Label>
            <CheckBox>
              <CheckBoxContent>{base.segmento === 5 && "X"}</CheckBoxContent>
            </CheckBox>
          </InnerRowContainer>
        </RowContainer>
        {/**Irmãos que estudam na escola */}
        <BoxContainer>
          <InnerColumnContainer>
            <RowContainer>
              <Label style={{ paddingRight: "100px" }}>Tem irmão(s) que estuda(m) na escola? </Label>
              <InnerRowContainer>
                <Label style={{ marginLeft: 10 }}>Não</Label>
                <CheckBox>
                  <CheckBoxContent>{base.temirmaos === "nao" && "X"}</CheckBoxContent>
                </CheckBox>
              </InnerRowContainer>
              <InnerRowContainer>
                <Label>Sim</Label>
                <CheckBox>
                  <CheckBoxContent>{base.temirmaos === "sim" && "X"}</CheckBoxContent>
                </CheckBox>
              </InnerRowContainer>
            </RowContainer>
            <RowContainer>
              <InnerRowContainer>
                <Label>Cite nome(s) e ano(s):</Label>
                <DataLabel>{base.irmaos}</DataLabel>
              </InnerRowContainer>
            </RowContainer>
          </InnerColumnContainer>
        </BoxContainer>

        {/*Dados Complementares */}
        <ColumnContainer>
          <BoldLabel>DADOS COMPLEMENTARES</BoldLabel>
          <BoxContainer>
            <InnerColumnContainer>
              <Label>Endereço Completo</Label>
              <RowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>Rua: </Label>
                  <DataLabel>{complementares.rua}</DataLabel>
                </InnerRowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>Nº: </Label>
                  <DataLabel>{complementares.numero}</DataLabel>
                </InnerRowContainer>
              </RowContainer>
              <RowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>Bairro: </Label>
                  <DataLabel>{complementares.bairro}</DataLabel>
                </InnerRowContainer>
                <InnerRowContainer>
                  <Label>Cidade: </Label>
                  <DataLabel>{complementares.cidade}</DataLabel>
                </InnerRowContainer>
                <InnerRowContainer>
                  <Label>CEP: </Label>
                  <DataLabel>{complementares.cep}</DataLabel>
                </InnerRowContainer>
              </RowContainer>
              <RowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>Tel. / Celular: </Label>
                  <DataLabel>{complementares.celular}</DataLabel>
                </InnerRowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>E-mail: </Label>
                  <DataLabel>{complementares.email}</DataLabel>
                </InnerRowContainer>
              </RowContainer>
              <InnerColumnContainer>
                <InnerRowContainer>
                  <Label>Nome do Responsável Financeiro:</Label>
                  <DataLabel>{complementares.responsavel_financeiro}</DataLabel>
                </InnerRowContainer>
                <RowContainer>
                  <Label>O candidato reside com: </Label>
                  <RowContainer>
                    <InnerRowContainer>
                      <Label style={{ marginLeft: 10 }}>pai e mãe</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {complementares.reside_com === "paiemae" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>pai</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {complementares.reside_com === "pai" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>mãe</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {complementares.reside_com === "mae" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>outro</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {complementares.reside_com_outro && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                  </RowContainer>
                </RowContainer>
                <DataLabel style={{ marginLeft: 0 }}>
                  {complementares.reside_com_outro}
                </DataLabel>
              </InnerColumnContainer>
            </InnerColumnContainer>
          </BoxContainer>
        </ColumnContainer>

        {/*Dados dos Responsaveis */}
        {responsaveis &&
          Object.entries(responsaveis).map(([key, value]) => {
            return (
              <ColumnContainer key={key}>
                <BoldLabel>DADOS COMPLEMENTARES - {value.tipo ? value.tipo.toUpperCase() : ""}</BoldLabel>
                <BoxContainer>
                  <InnerColumnContainer>
                    <InnerRowContainer style={{ marginLeft: 0 }}>
                      <Label>Nome Completo: </Label>
                      <DataLabel>{value.nome}</DataLabel>
                    </InnerRowContainer>
                    <RowContainer>
                      <InnerRowContainer style={{ marginLeft: 0 }}>
                        <Label>CPF: </Label>
                        <DataLabel>{value.cpf}</DataLabel>
                      </InnerRowContainer>
                      <InnerRowContainer style={{ marginLeft: 0 }}>
                        <Label>RG: </Label>
                        <DataLabel>{value.ci}</DataLabel>
                      </InnerRowContainer>
                    </RowContainer>
                    <InnerRowContainer style={{ marginLeft: 0 }}>
                      <Label>Profissão: </Label>
                      <DataLabel>{value.profissao}</DataLabel>
                    </InnerRowContainer>
                    <RowContainer>
                      <InnerRowContainer style={{ marginLeft: 0 }}>
                        <Label>Condição de trabalho:</Label>
                        <RowContainer>
                          <InnerRowContainer>
                            <Label style={{ marginLeft: 10 }}>empresário</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.condicao_trabalho === "empresario" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                          <InnerRowContainer>
                            <Label>assalariado</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.condicao_trabalho === "assalariado" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                          <InnerRowContainer>
                            <Label>autônomo</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.condicao_trabalho === "autonomo" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                          <InnerRowContainer>
                            <Label>funcionário público</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.condicao_trabalho === "funcionario" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                        </RowContainer>
                      </InnerRowContainer>
                    </RowContainer>
                    <RowContainer>
                      <InnerRowContainer style={{ marginLeft: 0 }}>
                        <Label>Atualmente está:</Label>
                        <RowContainer>
                          <InnerRowContainer>
                            <Label style={{ marginLeft: 10 }}>empregado</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.atualmente_esta === "empregado" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                          <InnerRowContainer>
                            <Label>desempregado</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.atualmente_esta === "desempregado" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                          <InnerRowContainer>
                            <Label>aposentado</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.atualmente_esta === "aposentado" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                          <InnerRowContainer>
                            <Label>outra situação</Label>
                            <CheckBox>
                              <CheckBoxContent>
                                {value.atualmente_esta === "outra_situacao" && "X"}
                              </CheckBoxContent>
                            </CheckBox>
                          </InnerRowContainer>
                        </RowContainer>
                      </InnerRowContainer>
                    </RowContainer>
                    <RowContainer>
                      <InnerRowContainer style={{ marginLeft: 0 }}>
                        <Label>Renda complementar: </Label>
                        <DataLabel>{value.renda_complementar ? "R$ " + value.renda_complementar : ""}</DataLabel>
                      </InnerRowContainer>
                      <InnerRowContainer style={{ marginLeft: 0 }}>
                        <Label>Oriunda de: </Label>
                        <DataLabel>{value.oriunda_de}</DataLabel>
                      </InnerRowContainer>
                    </RowContainer>
                    {value.trabalhos.map((trabalho, index) => {
                      return (
                        <React.Fragment key={index + value.tipo} >
                          <div style={{ border: "1px solid #000", margin: "10px", padding: "5px" }}>
                            <InnerRowContainer style={{ marginLeft: 0 }}>
                              <Label>{Number(index) + 1}. Identificação de onde trabalha: </Label>
                              <DataLabel>{trabalho.onde_trabalha}</DataLabel>
                            </InnerRowContainer>
                            <RowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Endereço: </Label>
                                <DataLabel>
                                  {trabalho.rua} {trabalho.numero}{" "}
                                  {trabalho.bairro} {trabalho.cidade}
                                </DataLabel>
                              </InnerRowContainer>
                            </RowContainer>
                            <RowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Telefone: </Label>
                                <DataLabel>{trabalho.telefone}</DataLabel>
                              </InnerRowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Ramal: </Label>
                                <DataLabel>{trabalho.ramal}</DataLabel>
                              </InnerRowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Fax: </Label>
                                <DataLabel>{trabalho.fax}</DataLabel>
                              </InnerRowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Celular: </Label>
                                <DataLabel>{trabalho.celular}</DataLabel>
                              </InnerRowContainer>
                            </RowContainer>
                            <RowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>E-mail: </Label>
                                <DataLabel>{trabalho.email}</DataLabel>
                              </InnerRowContainer>
                            </RowContainer>
                            <RowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Cargo/Função: </Label>
                                <DataLabel>{trabalho.cargo}</DataLabel>
                              </InnerRowContainer>
                              <InnerRowContainer style={{ marginLeft: 0 }}>
                                <Label>Salário bruto: </Label>
                                <DataLabel>{trabalho.salario_bruto ? "R$ " + trabalho.salario_bruto : ""}</DataLabel>
                              </InnerRowContainer>
                            </RowContainer>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </InnerColumnContainer>
                </BoxContainer>
              </ColumnContainer>
            );
          })}

        {/**Composição Familiar */}
        <ColumnContainer>
          <BoldLabel>COMPOSIÇÃO FAMILIAR</BoldLabel>
          <BoxContainer>
            <InnerColumnContainer>
              <InnerRowContainer style={{ marginLeft: 0 }}>
                <Label>Preencher quadro abaixo: </Label>
              </InnerRowContainer>
              <BoxContainer>
                {/**Usando um for para cada parente */}
                {composicaoFamiliar.parentes &&
                  composicaoFamiliar.parentes.map((parente, index) => {
                    return (
                      <RowContainer key={String(index)}>
                        <InnerRowContainer style={{ marginLeft: 0 }}>
                          <Label>Nome: </Label>
                          <DataLabel>{parente.nome}</DataLabel>
                        </InnerRowContainer>
                        <InnerRowContainer style={{ marginLeft: 0 }}>
                          <Label>Parentesco: </Label>
                          <DataLabel>{parente.parentesco}</DataLabel>
                        </InnerRowContainer>
                        <InnerRowContainer style={{ marginLeft: 0 }}>
                          <Label>Idade: </Label>
                          <DataLabel>{parente.idade}</DataLabel>
                        </InnerRowContainer>
                        <InnerRowContainer style={{ marginLeft: 0 }}>
                          <Label>Profissão: </Label>
                          <DataLabel>{parente.profissao}</DataLabel>
                        </InnerRowContainer>
                        <InnerRowContainer style={{ marginLeft: 0 }}>
                          <Label>Salário bruto: </Label>
                          <DataLabel>{"R$ " + parente.salario_bruto}</DataLabel>
                        </InnerRowContainer>
                        <InnerRowContainer style={{ marginLeft: 0 }}>
                          <Label>Outras rendas: </Label>
                          <DataLabel>{parente.outras_rendas ? "R$ " + parente.outras_rendas : "----"}</DataLabel>
                        </InnerRowContainer>
                      </RowContainer>
                    );
                  })}
              </BoxContainer>
              <RowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>Residência em imóvel:</Label>
                  <RowContainer>
                    <InnerRowContainer>
                      <Label style={{ marginLeft: 10 }}>próprio</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.residencia_imovel === "proprio" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>alugado</Label>
                      <DataLabel>
                        {composicaoFamiliar.residencia_imovel === "alugado" && "R$ " + composicaoFamiliar.residencia_imovel_valor}
                      </DataLabel>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.residencia_imovel === "alugado" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>financiado</Label>
                      <DataLabel>
                        {composicaoFamiliar.residencia_imovel === "financiado" && "R$ " + composicaoFamiliar.residencia_imovel_valor}
                      </DataLabel>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.residencia_imovel === "financiado" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>familiar</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.residencia_imovel === "familiar" && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                  </RowContainer>
                </InnerRowContainer>
              </RowContainer>
              <RowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>Possui carro(s):</Label>
                  <RowContainer>
                    <InnerRowContainer>
                      <Label style={{ marginLeft: 10 }}>Sim</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.possui_carro && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>Não</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {!composicaoFamiliar.possui_carro && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>Quantos</Label>
                      <DataLabel>
                        {composicaoFamiliar.possui_carro_quantos}
                      </DataLabel>
                    </InnerRowContainer>
                  </RowContainer>
                </InnerRowContainer>
              </RowContainer>
              {/**Usar for para cada carro */}
              {composicaoFamiliar.carros &&
                composicaoFamiliar.carros.map((carro, index) => {
                  return (
                    <RowContainer key={String(index)}>
                      <InnerRowContainer>
                        <Label>Marca:</Label>
                        <DataLabel>{carro.marca}</DataLabel>
                      </InnerRowContainer>
                      <InnerRowContainer>
                        <Label>Modelo:</Label>
                        <DataLabel>{carro.modelo}</DataLabel>
                      </InnerRowContainer>
                      <InnerRowContainer>
                        <Label>Ano:</Label>
                        <DataLabel>{carro.ano}</DataLabel>
                      </InnerRowContainer>
                    </RowContainer>
                  );
                })}
              <RowContainer>
                <InnerRowContainer style={{ marginLeft: 0 }}>
                  <Label>
                    Além da residência, a família possui outro(s)
                    imóvel(eis) (fazenda, sítio, chácara, terreno, casa na
                    praia, etc.)?
                  </Label>
                  <RowContainer>
                    <InnerRowContainer>
                      <Label style={{ marginLeft: 10 }}>Sim</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.outros_imoveis && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>Não</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {!composicaoFamiliar.outros_imoveis && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>Quais? </Label>
                      <DataLabel>
                        {composicaoFamiliar.outros_imoveis_quais}
                      </DataLabel>
                    </InnerRowContainer>
                  </RowContainer>
                </InnerRowContainer>
              </RowContainer>
              <RowContainer>
                <Label>Há casos de doença na família: </Label>
                <RowContainer>
                  <InnerRowContainer style={{ marginLeft: 10 }}>
                    <Label>Sim</Label>
                    <CheckBox>
                      <CheckBoxContent>
                        {composicaoFamiliar.doencas_familia && "X"}
                      </CheckBoxContent>
                    </CheckBox>
                  </InnerRowContainer>
                  <InnerRowContainer>
                    <Label>Não</Label>
                    <CheckBox>
                      <CheckBoxContent>
                        {!composicaoFamiliar.doencas_familia && "X"}
                      </CheckBoxContent>
                    </CheckBox>
                  </InnerRowContainer>
                  <InnerRowContainer>
                    <Label>Qual? </Label>
                    <DataLabel>
                      {composicaoFamiliar.doencas_familia_qual}
                    </DataLabel>
                  </InnerRowContainer>
                  <InnerRowContainer>
                    <Label>Está em tratamento? </Label>
                    <InnerRowContainer style={{ marginLeft: 10 }}>
                      <Label>Sim</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {composicaoFamiliar.doencas_familia_tratamento && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                    <InnerRowContainer>
                      <Label>Não</Label>
                      <CheckBox>
                        <CheckBoxContent>
                          {!composicaoFamiliar.doencas_familia_tratamento && "X"}
                        </CheckBoxContent>
                      </CheckBox>
                    </InnerRowContainer>
                  </InnerRowContainer>
                </RowContainer>
              </RowContainer>
              <RowContainer>
                <InnerRowContainer>
                  <Label>Renda bruta familiar:</Label>
                  <DataLabel>{"R$ " + composicaoFamiliar.renda_familiar}</DataLabel>
                </InnerRowContainer>
              </RowContainer>
              <RowContainer>
                <InnerRowContainer>
                  <Label>Despesa familiar:</Label>
                  <DataLabel>{"R$ " + composicaoFamiliar.despesa_familiar}</DataLabel>
                </InnerRowContainer>
              </RowContainer>
              <RowContainer>
                <InnerRowContainer>
                  <Label>Quantas pessoas dependem desta renda? </Label>
                  <DataLabel>
                    {composicaoFamiliar.renda_familiar_dependentes}
                  </DataLabel>
                </InnerRowContainer>
                <InnerRowContainer>
                  <Label>Renda per capta: </Label>
                  <DataLabel>{"R$ " + composicaoFamiliar.renda_per_capta}</DataLabel>
                </InnerRowContainer>
              </RowContainer>
            </InnerColumnContainer>
          </BoxContainer>
        </ColumnContainer>

        {/**Despesa Familiar*/}
        <ColumnContainer>
          <BoldLabel>DESPESA FAMILIAR</BoldLabel>
          <BoxContainer>
            <InnerColumnContainer>
              {despesas &&
                Object.entries(despesas).map(([key, value]) => {
                  return (
                    <RowContainer key={key} style={{ alignItems: "baseline" }}>
                      <InnerRowContainer style={{ lineHeight: "25px", margin: "0px" }}>
                        {value.map(item => {
                          return (
                            <React.Fragment key={item.nome}>
                              {item.valor && <LabelDespesas>{item.nome}: </LabelDespesas>}
                              {item.valor && <DataLabel style={{ marginRight: "5px" }}>{"R$ " + item.valor}</DataLabel>}
                            </React.Fragment>
                          );
                        })}
                      </InnerRowContainer>
                    </RowContainer>
                  );
                })}
            </InnerColumnContainer>
          </BoxContainer>
        </ColumnContainer>
        {/**Declaração */}
        <ColumnContainer>
          <BoldLabel>DECLARAÇÃO</BoldLabel>
          <BoxContainer>
            <ColumnContainer>
              <Label>
                &nbsp;&nbsp;&nbsp;Declaro, sob pena de Lei 12.101/2009 e
                alterações posteriores, que as informações acima são verdadeiras
                e assumo inteira responsabilidade sobre as mesmas.
              </Label>
              <Label>
                &nbsp;&nbsp;&nbsp;Estou ciente de que a não veracidade e
                fidelidade das respostas desclassificará o estudante nos termos
                do Artigo 15, § 1º e 3º, da Lei 12.101/2009.{" "}
              </Label>{" "}
              <Label>
                &nbsp;&nbsp;&nbsp;A obtenção do desconto para o ano letivo nçao
                implicará na obrigatoriedade para os anos subsequentes.
              </Label>
            </ColumnContainer>
          </BoxContainer>
        </ColumnContainer>
        {/**Observações */}
        <ColumnContainer>
          <BoldLabel>OBSERVAÇÕES</BoldLabel>
          <BoxContainerObs tem_obs={base.obs ? true : false}>
            <DataLabel>{base.obs}</DataLabel>
          </BoxContainerObs>
        </ColumnContainer>
        {/**Parecer */}
        <ColumnContainer>
          <BoldLabel>PARECER DO SERVIÇO SOCIAL</BoldLabel>
          <BoxContainerParecer currentStatus={base.status}>
            {/* Se solicitacao ainda estiver aberta para analise */}
            {((base.status === "aguardando") || (base.status === "emanalise")) &&
              <TextField
                disabled={base.status === "emanalise" ? false : true}
                multiline={true}
                rows="3"
                value={base.parecer ? base.parecer : ""}
                onChange={e => setBase({ ...base, parecer: e.target.value })}
                InputProps={{ disableUnderline: true }}
              />
            }
            {/* Se solicitacao NAO estiver aberta para analise */}
            {((base.status !== "aguardando") && (base.status !== "emanalise")) &&
              <DataLabel>{base.parecer}</DataLabel>
            }
          </BoxContainerParecer>
        </ColumnContainer>
      </Form>
    );
  }

  async function handleChangeStatus(e: SyntheticEvent) {
    setLoading(true);
    e.preventDefault();

    const nextStatus = base.status === "aguardando" ? "emanalise" : "aguardando";

    try {
      const formData = new FormData();
      formData.append("status", nextStatus);
      const token = await localStorage.getItem("@CensaAssist:token");
      await api.post("ws/form/update/" + id, formData, {
        headers: {
          Authorization: `CensaApp ${token}`,
        },
      });
      setBase({ ...base, status: nextStatus });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();

    if (!base.parecer || (base.parecer === "")) {
      return window.alert("ERRO: Necessário inserir um parecer antes de concluir a análise.");
    }

    if (!window.confirm(confirmationMessage)) {
      return null;
    }

    setLoading(true);

    const date_now = dateNow(new Date());

    try {
      const formData = new FormData();
      formData.append("status", "emavaliacao");
      formData.append("parecer", base.parecer);
      formData.append("datahora_parecer", date_now);
      const token = await localStorage.getItem("@CensaAssist:token");
      await api.post("ws/form/update/" + id, formData, {
        headers: {
          Authorization: `CensaApp ${token}`,
        },
      });
      setLoading(false);
      window.alert("Análise concluída com sucesso.");
      history.push("/home", {
        userType: "social",
      });
    } catch (err) {
      console.log(err);
    }
  }

  function handleCopyLink(e: SyntheticEvent) {
    e.preventDefault();

    const link = document.createElement('textarea');
    link.value = process.env.REACT_APP_CENSASOCIAL_HOST + base.url;
    document.body.appendChild(link);
    link.select();

    try {
      setloadingMessageCopiedLink(true);
      document.execCommand('copy');
      setTimeout(() => {
        setloadingMessageCopiedLink(false);
      }, 2000);
    } catch (err) {
      window.alert("Erro ao copiar o link!");
    } finally {
      document.body.removeChild(link);
    }
  }

  async function handleDeleteForm(e: SyntheticEvent) {
    e.preventDefault();

    if (!window.confirm("Tem certeza que deseja apagar este formulário?")) {
      return null;
    }

    try {
      const formData = new FormData();
      const token = await localStorage.getItem("@CensaAssist:token");
      let response = await api.post("ws/form/remover/" + String(Number(id)), formData, {
        headers: {
          Authorization: `CensaApp ${token}`,
        },
      });

      if (response.status) {
        window.alert("Formulário apagado com sucesso!");

        history.push("/home", {
          userType: "social",
        });
      } else {
        window.alert("Erro ao apagar formulário.");
      }

      setLoading(false);

    } catch (err) {
      console.log(err);
    }
  }


  return (
    <Container style={loading ? { justifyContent: "center", flex: 1 } : {}}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <RowContainerHeader currentStatus={base.status}>
            <h2>Solicitação {id}</h2>
            <h3>
              Status: {base.status === "aguardando" ? "Aguardando análise"
                : base.status === "emanalise" ? "Em análise" : "Análise concluída"}
            </h3>
            {(base.status === "aguardando" || base.status === "emanalise") &&
              <Button onClick={(e: SyntheticEvent) => handleChangeStatus(e)}>
                {base.status === "aguardando" ? "Bloquear para análise" : "Liberar para edição"}
              </Button>}
          </RowContainerHeader>
          <RowContainer style={{ marginBottom: "5px", padding: "2px" }}>
            <RowContainer>
              <span style={{ color: "#00F", cursor: "pointer" }}
                onClick={(e: SyntheticEvent) => handleCopyLink(e)}
              >
                Copiar link do formulário
              </span>
              <span>Atualizado em {base.datahora}</span>
            </RowContainer>
            <Tooltip TransitionComponent={Zoom} title="Ver documentos em anexo" arrow>
              <IconButton
                color="primary"
                aria-label="Attachments"
                component="span"
                style={{ padding: "5px" }}
                onClick={() => setOpenModal(true)}
              >
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openModal}
              onClose={() => setOpenModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
              <Fade in={openModal}>
                <div className={classes.paper}>
                  <h2 id="transition-modal-title" style={{ paddingBottom: "10px" }}>Documentos anexados</h2>
                  {Object.entries(attachedDocs).map(([key, value]) => {
                    return (
                      <InnerRowContainer key={key} style={{ alignItems: "center" }}>
                        <p key={key} id="transition-modal-description" style={{ paddingRight: "10px" }}>. {value}</p>
                        <IconButton
                          component="button"
                          onClick={() => {
                            const new_url = process.env.REACT_APP_CENSASOCIAL_HOST + "/docs/" + base.formToken + "/" + value;
                            window.open(new_url, '_blank');
                          }}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </InnerRowContainer>
                    );
                  })}
                </div>
              </Fade>
            </Modal>
          </RowContainer>
          {loadingMessageCopiedLink &&
            <Alert severity="success" color="info" style={{ display: "flex", flexDirection: "row" }}>Link copiado com sucesso</Alert>
          }
          {renderForm()}
          {(base.status === "aguardando") &&
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <span style={{ color: "#F00", cursor: "pointer" }}
                onClick={(e: SyntheticEvent) => handleDeleteForm(e)}
              >
                Apagar formulário
              </span>
            </div>
          }
          {/* Se solicitacao ainda estiver aberta para analise */}
          {((base.status === "aguardando") || (base.status === "emanalise")) &&
            <ButtonSubmitContainer>
              <ButtonSubmit
                disabled={base.status === "emanalise" ? false : true}
                onClick={(e: SyntheticEvent) => handleSubmit(e)}
              >
                Concluir análise
              </ButtonSubmit>
            </ButtonSubmitContainer>
          }
          {/* Se solicitacao NAO estiver aberta para analise */}
          {((base.status !== "aguardando") && (base.status !== "emanalise")) &&
            <DataParecerLabel>A análise dessa solicitação foi concluída no dia {base.datahora_parecer_dia} às {base.datahora_parecer_horario}.</DataParecerLabel>
          }
        </>
      )}
    </Container>
  );
};

export { Solicitation };
