import axios from "axios";
require("cors");
const api = axios.create({
  baseURL: process.env.REACT_APP_CENSASOCIAL_HOST,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export default api;
