import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
    outline:0;
  }
  
  div {
    display:flex;
    flex-direction:column;
  }

  html, body, #root, .App {
    height: 100%;
  }

  iframe {
    display: grid;
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  span, p {
    font-family: "Roboto Slab", serif;
    color: #312e38;
  }

  input, button, body {
    font-family: "Roboto Slab", serif;
    font-size: 14px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    font-family: "Roboto Slab", serif;
  }

  button{
    cursor: pointer;
  }
`;
