import React, { SyntheticEvent, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Pagination from "@material-ui/lab/Pagination";

import api from "../../../services/api";

import { useAuth } from "../../../hooks/auth";

import { ReactComponent as Logo } from "../../../assets/images/censa_social_logo.svg";

import {
  Container,
  Loader,
  InnerListContainer,
  ListsContainer,
  ListContainer,
  ListTitleOpen,
  ListTitleClosed,
  ListItem,
  SearchContainer,
  WelcomeContainer,
  Welcome,
  Search,
  TypeSearch,
  SubmitSearch,
} from "./styles";

const openNames = [];
const closedNames = [];

const offset = 10;

const Home: React.FC = () => {
  const history = useHistory();

  const { signOut } = useAuth();
  const [namePerson, setNamePerson] = useState("");

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("name");
  const [searchStatusTab, setSearchStatusTab] = useState("opened");

  const [openSolicitations, setOpenSolicitations] = useState(openNames);
  const [closedSolicitations, setClosedSolicitations] = useState(closedNames);
  const [openSolicitationsFilter, setOpenSolicitationsFilter] = useState(openNames);
  const [closedSolicitationsFilter, setClosedSolicitationsFilter] = useState(closedNames);

  const [offsetOpen, setOffsetOpen] = useState(Math.ceil(openSolicitationsFilter.length / offset));
  const [offsetClosed, setOffsetClosed] = useState(Math.ceil(closedSolicitationsFilter.length / offset));
  const [currentPageOpen, setCurrentPageOpen] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const person = localStorage.getItem("@CensaAssist:name");
      setNamePerson(person);

      const token = localStorage.getItem("@CensaAssist:token");
      const AuthStr = "Censaapp " + token;

      const response = await api.get("/ws/form/listar", { 'headers': { 'Authorization': AuthStr } });

      let openedReqs = [];
      let closedReqs = [];

      response.data.return.map(socialReq => {
        if ((socialReq.parecer !== null) && (socialReq.status === "emavaliacao")) {
          openedReqs.push(socialReq);
        } else if ((socialReq.status === "deferido") || (socialReq.status === "indeferido")) {
          closedReqs.push(socialReq);
        }
      });

      setOpenSolicitations(openedReqs);
      setClosedSolicitations(closedReqs);
      setOpenSolicitationsFilter(openedReqs);
      setClosedSolicitationsFilter(closedReqs);

      setLoading(false);
    }

    getData();
  }, []);

  function handleSearchSubmit(evt: SyntheticEvent) {
    evt.preventDefault();

    if (searchValue === "") {
      setOpenSolicitationsFilter(openSolicitations);
      setClosedSolicitationsFilter(closedSolicitations);
    } else {
      let searchValue_nomalized: string;

      if (searchStatusTab === "opened") {
        switch (searchType) {
          case "name":
            searchValue_nomalized = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
            setOpenSolicitationsFilter(openSolicitations.filter(item => item.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().indexOf(searchValue_nomalized) !== -1));
            break;

          case "id":
            searchValue_nomalized = String(Number(searchValue));
            setOpenSolicitationsFilter(openSolicitations.filter(item => String(Number(item.id)) === searchValue_nomalized));
            break;
        }
      } else if (searchStatusTab === "closed") {
        switch (searchType) {
          case "name":
            searchValue_nomalized = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
            setClosedSolicitationsFilter(closedSolicitations.filter(item => item.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().indexOf(searchValue_nomalized) !== -1));
            break;

          case "id":
            searchValue_nomalized = String(Number(searchValue));
            setClosedSolicitationsFilter(closedSolicitations.filter(item => String(Number(item.id)) === searchValue_nomalized));
            break;
        }
      }
    }
  }

  function handleShowAll(e: SyntheticEvent) {
    e.preventDefault();

    setOpenSolicitationsFilter(openSolicitations);
    setClosedSolicitationsFilter(closedSolicitations);
    setSearchValue("");
    setSearchType("name");
    setSearchStatusTab("opened");
  }

  function renderClosedSolicitations() {
    return closedSolicitationsFilter.map((item, index) => {
      if (
        index >= offset * (currentPageClosed - 1) &&
        index < offset * currentPageClosed
      ) {
        return (
          <ListItem
            key={String(index)}
            onClick={() => {
              history.push("/solicitation", {
                userType: "direcaogeral",
                id: item.id,
              });
            }}
          >
            {item.id + " - " + item.nome}
          </ListItem>
        );
      }
    });
  }

  function renderOpenSolicitations() {
    return openSolicitationsFilter.map((item, index) => {
      if (
        index >= offset * (currentPageOpen - 1) &&
        index < offset * currentPageOpen
      ) {
        return (
          <ListItem
            onClick={() =>
              history.push("/solicitation", {
                userType: "direcaogeral",
                id: item.id,
              })
            }
            key={String(index)}
          >
            {item.id + " - " + item.nome}
          </ListItem>
        );
      }
    });
  }

  function handleSignOut(e: SyntheticEvent) {
    e.preventDefault();
    signOut();
    history.push("/");
  }

  return (
    <Container
      style={
        loading
          ? {
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }
          : {}
      }
    >
      {loading ? (
        <Loader />
      ) : (
          <>
            <Logo width="300" style={{ alignSelf: "center" }} />
            <WelcomeContainer>
              <Welcome style={{ fontSize: "16px" }}>Olá, {namePerson}</Welcome>
              <Welcome
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={(e: SyntheticEvent) => handleSignOut(e)}
              >
                Sair
              </Welcome>
            </WelcomeContainer>

            <SearchContainer
              onSubmit={(e: SyntheticEvent) => handleSearchSubmit(e)}
            >
              <Search
                value={searchValue}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setSearchValue(e.currentTarget.value)
                }
                placeholder="Sua busca"
              />
              <TypeSearch
                value={searchType}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setSearchType(e.currentTarget.value);
                }}
                name="Search_field"
              >
                <option value="name">Nome</option>
                <option value="id">Código</option>
              </TypeSearch>
              <TypeSearch
                value={searchStatusTab}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setSearchStatusTab(e.currentTarget.value);
                }}
                name="Search_statusTab"
              >
                <option value="opened">Revisadas</option>
                <option value="closed">Concluídas</option>
              </TypeSearch>
              <SubmitSearch type="submit">Buscar</SubmitSearch>
              <SubmitSearch type="button" onClick={(e: SyntheticEvent) => handleShowAll(e)}>Mostrar tudo</SubmitSearch>
            </SearchContainer>
            <ListsContainer>
              <ListContainer>
                <ListTitleOpen>Solicitações revisadas pela Assistente Social</ListTitleOpen>
                <InnerListContainer>
                  {renderOpenSolicitations()}
                </InnerListContainer>
                {offsetOpen > 1 && (
                  <Pagination
                    // @ts-ignore
                    style={{ alignSelf: "center" }}
                    onChange={(_, value) => setCurrentPageOpen(value)}
                    count={offsetOpen}
                    variant="outlined"
                    shape="rounded"
                  />
                )}
              </ListContainer>
              <ListContainer>
                <ListTitleClosed>
                  Solicitações concluídas
              </ListTitleClosed>
                <InnerListContainer>
                  {renderClosedSolicitations()}
                </InnerListContainer>
                {offsetClosed > 1 && (
                  <Pagination
                    // @ts-ignore
                    style={{ alignSelf: "center" }}
                    onChange={(_, value) => setCurrentPageClosed(value)}
                    count={offsetClosed}
                    variant="outlined"
                    shape="rounded"
                  />
                )}
              </ListContainer>
            </ListsContainer>
          </>
        )}
    </Container>
  );
};

export { Home };
